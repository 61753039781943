define(['app', '$window', 'accessibilityFocusHelper'], function (app, $window, accessibilityFocusHelper) {
  const accordionWidget = function () {
    const comp = {};
    const _config = {
      attribs: {
        ariaHidden: 'aria-hidden',
        ariaExpanded: 'aria-expanded',
      },
      selectors: {
        title: '.accordionWidget_sectionTitle',
        content: '.accordionWidget_sectionContent',
        focusableElements: 'button, a',
        hideElement: '.until_found'
      },
      tracking: {
        enabled: false,
        channel: 'accordionWidget',
        extra: undefined,
      },
      animationTime: 150,
      offset: 0,
      isOpen: false,
    };

    const _init = function (el, config = {}) {
      comp.config = Object.assign({}, comp.config, config);

      if(!comp.config.selectors.focusableElements){
        comp.config.selectors.focusableElements = _config.selectors.focusableElements;
      }

      comp.element = el;
      comp.title = el.querySelector(comp.config.selectors.title);
      comp.content = el.querySelector(comp.config.selectors.content);
      comp.title.addEventListener('click', comp.toggle);
      comp.hideElement = el.querySelector(comp.config.selectors.hideElement);
      comp.hideElementHelp = el.querySelector('.until_found_help');

      // Init check
      comp.checkState();

      // Run on window resize
      $window.addEventListener('optimizedResize', comp.resizeEvent, false);
      comp.throttle('resize', 'optimizedResize');

      return comp;
    };

    const _checkState = function () {
      if (comp.config.isOpen) {
        comp.open();
      } else {
        comp.close();
      }
    };

    const _getWindowWidth = () => {
      return $window.innerWidth;
    };

    const _resizeEvent = function () {
      comp.windowWidthCurrent = comp.getWindowWidth();

      // Check window width has actually changed and it's not just iOS triggering a resize event on scroll
      if ($window.innerWidth != comp.windowWidthCurrent) {

        // Update the window width for next time
        comp.windowWidthCurrent = $window.innerWidth
      }
      comp.checkState();
    };

    const _throttle = function (type, name, comp) {
      comp = comp || $window;
      let running = false;
      let func = function () {
        if (running) {
          return;
        }
        running = true;
        requestAnimationFrame(function () {
          comp.dispatchEvent(new CustomEvent(name));
          running = false;
        });
      };
      comp.addEventListener(type, func);
    };

    const _toggle = function () {
      // reset the height so css can interpolate
      const height = comp.title.offsetHeight + comp.content.offsetHeight;
      comp.element.style['max-height'] = height + 'px';
      if (comp.timeout) {
        $window.clearTimeout(comp.timeout);
        comp.timeout = null;
      }
      // set the max-height accordingly, must be deffered
      if (!comp.opened) {
        comp.timeout = $window.setTimeout(comp.open, comp.config.animationTime);
        // if the widget is inside a collection, trigger the collection
        if (comp.element.collection) {
          comp.element.collection.trigger(comp);
        }
        if (comp.config.tracking.enabled) {
          app.publish('tracking/record', comp.config.tracking.channel, 'open', 'accordion', comp.config.tracking.extra);
        }
      } else {
        comp.timeout = $window.setTimeout(comp.close, 0);
        if (comp.config.tracking.enabled) {
          app.publish('tracking/record', comp.config.tracking.channel, 'close', 'accordion', comp.config.tracking.extra);
        }
      }
      comp.opened = !comp.opened;
    };

    const _open = function () {
      comp.element.style['max-height'] = 'none';
      comp.title.setAttribute(comp.config.attribs.ariaExpanded, 'true');
      comp.content.setAttribute(comp.config.attribs.ariaHidden, 'false');
      accessibilityFocusHelper.enableElements(comp.content.querySelectorAll(comp.config.selectors.focusableElements));
      comp.element.classList.add('accordionWidget_expanded');

      if (comp.hideElement) {
        comp.hideElement.removeAttribute('hidden', 'until-found');
      }

      if (comp.hideElementHelp) {
        comp.hideElementHelp.removeAttribute('hidden', 'until-found');
      }

      comp.timeout = null;
      comp.opened = true;
    };

    const _close = function () {
      if (comp.title.offsetHeight != 0) {
        comp.element.style['max-height'] = `${comp.title.offsetHeight + comp.config.offset}px`;
      }
      comp.title.setAttribute(comp.config.attribs.ariaExpanded, 'false');
      comp.content.setAttribute(comp.config.attribs.ariaHidden, 'true');
      accessibilityFocusHelper.disableElements(comp.content.querySelectorAll(comp.config.selectors.focusableElements));

      if (comp.element.classList.contains('accordionWidget_expanded')){
        comp.element.classList.remove('accordionWidget_expanded')
        if (comp.hideElement) {
          comp.hideElement.setAttribute('hidden', 'until-found');
        }
        if (comp.hideElementHelp) {
          comp.hideElementHelp.setAttribute('hidden', 'until-found');
        }
      }



      comp.timeout = null;
      comp.opened = false;
    };

    comp.init = _init;
    comp.config = Object.assign({}, _config);
    comp.checkState = _checkState;
    comp.getWindowWidth = _getWindowWidth;
    comp.resizeEvent = _resizeEvent;
    comp.throttle = _throttle;
    comp.toggle = _toggle;
    comp.open = _open;
    comp.close = _close;
    return comp;

  };
  return accordionWidget;
});

